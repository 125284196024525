<template>
    <b-modal v-model="mMostrar" title="Ubicación" scrollable>


        <form role="form" class="pt--7 my-0">
            <small v-if="mUbicacion.nombre!==''" class="text-muted">Nombre</small>
            <base-input alternative=""
                        placeholder="Nombre de la ubicación"
                        input-classes="form-control-alternative"
                        v-model="mUbicacion.nombre" class="mb-3" />
            <base-checkbox class="mb-3 " v-model="mUbicacion.confirmada">
                He verificado la ubicación
            </base-checkbox>
            <small v-if="mUbicacion.descripcion!==''" class="text-muted">Descripción</small>
            <textarea class="form-control" id="exampleFormControlTextarea1" v-model="mUbicacion.comentarios" rows="2" placeholder="coloca cualquier información que quieras almacenar para usar despues"></textarea>
            <small class="text-muted">Posición en mapa</small>
            <div class="card shadow border-0">
                <div id="ubicacion-map-canvas" class="ubicacion-map-canvas" style="height: 300px;"></div>
            </div>
            <div class="d-flex flex-row-reverse bd-highlight">
                <base-button primary size="sm" type="secondary" class="" icon="fas fa-caret-up" v-on:click="Direccion2GPS">
                </base-button>
                <base-button primary size="sm" type="secondary" class="" icon="fas fa-caret-down" v-on:click="GPS2Direccion">
                </base-button>
            </div>
            <small class="text-muted">Precisión</small>
            <base-slider v-model="Precision" :range="{min: 5, max: 500}" :options="Opciones"></base-slider>
            <h6 class="heading-small text-muted mb-2">Dirección</h6>
            <div class="pl-lg-4">
                <small class="text-muted">País</small>
                <base-radio name="México" class="mb-1" v-model="Direccion.Pais">
                    <img src="/img/flags/MX.png" class="mr-2" />México
                </base-radio>
                <base-radio name="Perú" class="mb-1" v-model="Direccion.Pais">
                    <img src="/img/flags/PE.png" class="mr-2" />Perú
                </base-radio>
                <base-radio name="Colombia" class="mb-1" v-model="Direccion.Pais">
                    <img src="/img/flags/CO.png" class="mr-2" />Colombia
                </base-radio>
                <base-radio name="Chile" class="mb-1" v-model="Direccion.Pais">
                    <img src="/img/flags/CL.png" class="mr-2" />Chile
                </base-radio>
                <base-radio name="Argentina" class="mb-1" v-model="Direccion.Pais">
                    <img src="/img/flags/AR.png" class="mr-2" />Argentina
                </base-radio>
                <base-radio name="USA" class="mb-1" v-model="Direccion.Pais">
                    <img src="/img/flags/US.png" class="mr-2" />USA
                </base-radio>

                <small v-if="Direccion.DirCalle!==''" class="text-muted">Calle</small>
                <base-input alternative
                            class="mb-3"
                            placeholder="Calle"
                            v-model.trim="Direccion.DirCalle">
                </base-input>
                <div class="row">
                    <div class="col-6">


                        <small v-if="Direccion.DirCalleNo!==''" class="text-muted">No.</small>
                        <base-input alternative
                                    class="mb-3"
                                    placeholder="Número Exterior"
                                    v-model.trim="Direccion.DirCalleNo">
                        </base-input>
                    </div>
                    <div class="col-6 ">
                        <small v-if="Direccion.DirCalleNo!==''" class="text-muted">No.Int.</small>
                        <base-input alternative
                                    class="mb-3"
                                    placeholder="Número Interior"
                                    v-model.trim="Direccion.DirInterior">
                        </base-input>
                    </div>
                </div>
                <small v-if="Direccion.Colonia!==''" class="text-muted">Colonia</small>
                <base-input alternative
                            class="mb-3"
                            placeholder="Colonia"
                            v-model.trim="Direccion.Colonia">
                </base-input>
                <small v-if="Direccion.Municipio!==''" class="text-muted">Municipio / Alcaldía</small>
                <base-input alternative
                            class="mb-3"
                            placeholder="Municipio / Alcaldía"
                            v-model.trim="Direccion.Municipio">
                </base-input>
                <small v-if="Direccion.Ciudad!==''" class="text-muted">Ciudad</small>
                <base-input alternative
                            class="mb-3"
                            placeholder="Ciudad"
                            v-model.trim="Direccion.Ciudad">
                </base-input>
                <small v-if="Direccion.Estado!==''" class="text-muted">Estado</small>
                <base-input alternative
                            class="mb-3"
                            placeholder="Estado"
                            v-model.trim="Direccion.Estado">
                </base-input>
                <small v-if="Direccion.CP!==''" class="text-muted">Código Postal</small>
                <base-input alternative
                            class="mb-3"
                            placeholder="Código Postal"
                            v-model.trim="Direccion.CP">
                </base-input>
            </div>

        </form>
        <template v-slot:modal-footer>
            <div class="d-flex justify-content-between">
                <base-button size="sm" type="danger" icon="fas fa-trash" v-on:click="Borrar" class="my--2 " :disabled="Guardando">Borrar</base-button>
                <base-button size="sm" type="default" icon="fas fa-save" v-on:click="Guardar" class="my--2 " :disabled="Guardando">Guardar</base-button>


            </div>
        </template>
    </b-modal>
</template>

<script>
    import Ubicaciones from '@/api-services/ubicaciones.service';
  import Direcciones from '@/api-base/direcciones.service';
    export default {
        name: "UbicacionesEdicionComp",
        props: {
            /*   Guardando: {
                   type: Boolean,
                   default: false,
                   description: "Indica si se esta guardando la información"
               },*/
            Mostrar: {
                type: Boolean,
                default: false,
                description: "Indica si se mostrará"
            },
            Ubicacion: Object
        },
        data() {
            return {
                mUbicacion: {},
                Direccion: {},
                mPrecision: 30,
                Precision: 30,
                mMostrar: false,
                Marker: {},
                Circle: null,
                Map: null,
                Guardando: false,
                GPS: { Lat: 19.4270219, Lng: - 99.1681479, Acc: 30 },
                Opciones: {
                    step: 1
                }
            };
        },
        computed: {
            /*    Direccion: function () {
                    var R = "";
                    if (this.mUbicacion != undefined && this.mUbicacion != null) {
                        var Direccion = {
                            Pais: this.Pais,
                            DirCalle: this.DirCalle,
                            DirCalleNo: this.DirCalleNo,
                            DirInterior: this.DirInterior,
                            Colonia: this.Colonia,
                            Municipio: this.Municipio,
                            Ciudad: this.Ciudad,
                            Estado: this.Estado,
                            CP: this.CP,

                        };
                        R = JSON.stringify(Direccion);
                    }
                    return R;
                }*/
        },
        watch: {
            mMostrar: function (val) {
                //  console.debug(`mMostrar = ${val}`);
                if (this.Mostrar != val) {
                    this.$emit('update:mostrar', val)
                    // this.Mostrar = val;
                }
            },
            Precision: function (val) {

                this.mPrecision = val;
                if (this.Circle != null) {
                    //  this.ActualizaPin();
                    this.Circle.setRadius(parseFloat(this.mPrecision));
                }
                this.GPS.Acc = val;
            },

            Mostrar(Mostrar) {
                this.mMostrar = Mostrar;
            },
            Ubicacion(Ubicacion) {

                this.mUbicacion = Ubicacion;
                this.Direccion = Direcciones.sDireccion2Direccion(Ubicacion.direccion);
                //console.debug(this.mUbicacion.gps);
                if (this.mUbicacion.gps !== undefined && this.mUbicacion.gps !== null && this.mUbicacion.gps !== "" && this.mUbicacion.gps !== "{}") {
                    this.GPS = JSON.parse(this.mUbicacion.gps);
                    console.debug(this.GPS);
                    if (this.GPS.Acc == undefined)
                        this.GPS.Acc = this.mPrecision;

                    if (this.Marker != null) {
                        let google = window.google;
                        const NPos = new google.maps.LatLng(this.GPS.Lat, this.GPS.Lng);


                        this.Marker.setPosition(NPos);
                        //setTimeout(() => { this.Marker.setPosition(NPos); }, 2000);

                        this.Circle.setCenter(NPos);
                        //this.Circle.setRadius(parseFloat(this.GPS.Acc));
                        this.Map.setCenter(NPos);
                    }
                    this.Precision = this.GPS.Acc;
                    /*  var Bounds = this.Circle.getBounds();
                      console.debug(Bounds);
                     // this.Map.fitBounds(Bounds, 10);*/
                }
                else
                    this.GPS = { Lat: 19.4270219, Lng: - 99.1681479, Acc: this.mPrecision };
            }
        },

        methods: {
            ActualizaPin() {
                let google = window.google
                this.Map = document.getElementById('ubicacion-map-canvas');

                const NPos = new google.maps.LatLng(this.GPS.Lat, this.GPS.Lng);
                console.debug(NPos);
                const mapOptions = {
                    zoom: 16,
                    scrollwheel: true,
                    center: NPos,

                    mapTypeId: google.maps.MapTypeId.ROADMAP,

                }
                this.Map = new google.maps.Map(this.Map, mapOptions);
                //map.center = NPos;
                this.Marker = new google.maps.Marker({
                    position: NPos,
                    map: this.Map,
                    draggable: true,
                    animation: google.maps.Animation.DROP,
                    title: 'Ubicación'
                });


                //console.debug(this.mPrecision);
                this.Circle = new google.maps.Circle({
                    center: this.Marker.position,
                    radius: parseFloat(this.mPrecision),
                    map: this.Map,//your map,
                    fillColor: '#FF0000',//color,
                    fillOpacity: 0.35,//opacity from 0.0 to 1.0,
                    strokeColor: '#FF0000', //stroke color,
                    strokeOpacity: 0.8,//opacity from 0.0 to 1.0
                    // animation: google.maps.Animation.DROP,
                });
                google.maps.event.addListener(this.Marker, 'drag', (event) => {
                    console.debug('new position is ' + event.latLng.lat() + ' / ' + event.latLng.lng());
                    this.Circle.setCenter(this.Marker.position);
                    // this.Circle.center = this.Marker.position;
                });

                google.maps.event.addListener(this.Marker, 'dragend', (event) => {
                    console.debug('final position is ' + event.latLng.lat() + ' / ' + event.latLng.lng());
                    this.GPS.Lat = event.latLng.lat();
                    this.GPS.Lng = event.latLng.lng();
                    console.debug(this.GPS);
                    this.Circle.setCenter(this.Marker.position);// new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
                });
            },
            Borrar() {
                this.Guardando = true;
                Ubicaciones.delete(this.Ubicacion.ubicacionID).then((response) => {
                    this.Guardando = false;

                    Ubicaciones.ActualizaLocal(response.data);
                    this.$emit('borrado', this.Ubicacion);
                    this.mMostrar = false;
                    this.$notify({ type: 'success', title: 'Borrado' });
                }).catch(() => {
                    this.Guardando = false;
                    this.$notify({ type: 'danger', title: 'Error desconocido, intente nuevamente"' });

                });
            },

            Guardar() {
                /* if (Usuarios.PerfilLocal == null)
                     return;
                 if (this.location == undefined || this.location == null || this.location.coords == undefined || this.location.coords == null)
                     return;
                 //console.debug(Ubicaciones.ObtenUbicacionesGPS(this.location.coords.latitude, this.location.coords.longitude, this.location.coords.accuracy));
                 this.Ubicaciones = Ubicaciones.ObtenUbicacionesGPS(this.location.coords.latitude, this.location.coords.longitude, this.location.coords.accuracy);
                 this.CargoUbicaciones = true;*/
                this.mUbicacion.direccion = JSON.stringify(this.Direccion);
                this.mUbicacion.gps = JSON.stringify(this.GPS);
                console.debug(this.mUbicacion);

                var Metodo = null;
                var EsNueva = true;
                if (this.mUbicacion.ubicacionID !== undefined && this.mUbicacion.ubicacionID !== null) {
                    EsNueva = false;
                    Metodo = Ubicaciones.put(this.mUbicacion);
                }
                else {
                    Metodo = Ubicaciones.post(this.mUbicacion);
                }
                this.Guardando = true;
                Metodo.then((response) => {
                    this.Guardando = false;
                    var Guardado = response.data;
                    console.debug(Guardado);
                    Ubicaciones.ActualizaLocal(Guardado);
                  //  console.debug(Ubicaciones.UbicacionesLocales);
                    this.mMostrar = false;
                    this.Ubicacion.ubicacionID = Guardado.ubicacionID;
                    //Notifica del cambio
                    this.$emit('update:Ubicacion', Guardado);
                    if (EsNueva)
                        this.$emit('nueva', Guardado)
                    this.$notify({ type: 'success', title: 'Guardado' });
                }).catch((error) => {
                    this.Guardando = false;
                    if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

                        console.error(error);
                    }
                    else {

                        console.error(error.response.data.error);
                    }
                    this.$notify({ type: 'danger', title: 'Error desconocido, intente nuevamente"' });
                });
                // this.props.Mostrar = false;
            },
            ActualizaDireccion() {


            },
            Direccion2GPS() {
                let google = window.google
                var geocoder = new google.maps.Geocoder();
                var address = Direcciones.Direccion2Texto(this.Direccion);
                //   console.debug(address);
                geocoder.geocode({ 'address': address }, (results, status) => {
                    if (status == 'OK') {
                        var latLng = results[0].geometry.location;
                        //this.Marker.position = latLng;
                        this.Marker.setPosition(latLng);
                        this.GPS.Lat = latLng.lat();
                        this.GPS.Lng = latLng.lng();
                        //console.debug(this.GPS);
                        this.Circle.setCenter(latLng);
                        this.Map.setCenter(latLng);


                    } else {
                        alert('Geocode was not successful for the following reason: ' + status);
                    }
                });
            },

            GPS2Direccion() {
                let google = window.google
                var geocoder = new google.maps.Geocoder();
                var latlng = { lat: this.GPS.Lat, lng: this.GPS.Lng };
                geocoder.geocode({ 'location': latlng }, (results, status) => {
                    if (status === 'OK') {
                        console.debug(results);
                        if (results[0]) {
                            var D = Direcciones.GoogleAddress2Direccion(results[0]);
                            //       console.debug(D);
                            this.Direccion = D;
                            //         console.debug(this.Direccion);
                            /*
                            this.Marker.position = latlng;
                            this.GPS.Lat = latLng.lat();
                            this.GPS.Lng = latLng.lng();
                            //console.debug(this.GPS);
                            this.Circle.setCenter(this.Marker.position);
                            this.Map.setCenter(this.Marker.position);
                           /* map.setZoom(11);
                            var marker = new google.maps.Marker({
                                position: latlng,
                                map: map
                            });
                            infowindow.setContent(results[0].formatted_address);
                            infowindow.open(map, marker);*/
                        } else {
                            window.alert('No results found');
                        }
                    } else {
                        window.alert('Geocoder failed due to: ' + status);
                    }
                });

            }

        },
        mounted: function () {
            this.ActualizaPin();
        },
    };
</script>
