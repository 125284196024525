<template>
  <div class="  p-3">

    <div slot="header ">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">{{Categoria}}</h5>
      <router-link :to="{}" class="pull-right p-2" @click.native="Nuevo"><i class="fas fa-plus"></i></router-link>
      <h3 class="card-title">{{Titulo}}</h3>
      <span class="text-muted">{{Ayuda}}</span>
    </div>

    <MazDraggableList v-model="Campos" v-slot="{ item, index }" itemKey="nombre" class="">

      <div class="d-flex justify-content-between w-100 m-0   ">


        <p class="m-0 text-light p-0">
          {{ item.etiqueta }}({{ item.nombre }})
        </p>
        <div>
          <router-link :to="{}" class="m-0 p-2" @click.native="Editar(item)"><i class="fas fa-pen"></i></router-link>
          <router-link :to="{}" class="m-0 p-2" @click.native="Borrar(item)"><i class="fa fa-times"></i></router-link>
        </div>
      </div>
    </MazDraggableList>

    <modal :show.sync="Modal"
           class="modal-black" centered :appendToBody="false"
           modalContentClasses="card"
           headerClasses="card-header">

      <div slot="header">
        <!-- <i class="fas fa-users fa-3x"></i>-->
        <h5 class="card-category">{{Categoria}}</h5>
        <h3 class="card-title">Editando Campo</h3>
      </div>

      <template>

        <ValidationObserver v-slot="{ handleSubmit}">
          <form v-if="Modal" @submit.prevent="handleSubmit(guardar)">

            <MazInput v-model="Seleccionado.etiqueta"
                      placeholder="Etiqueta"
                      :autocomplete="autocompleteEtiqueta" :disabled="loading || cargando"
                      class="maz-mb-3" required :loading="loading || cargando"
                      clearable>
              <template v-slot:icon-left>
                <i class="fas fa-tag"></i>
              </template>
            </MazInput>


            <MazInput v-model="Seleccionado.nombre"
                      placeholder="Nombre"
                      :autocomplete="autocompleteLinkID" :disabled="loading || cargando"
                      class="maz-mb-3"  required :loading="loading || cargando"
                      clearable>
              <template v-slot:icon-left>
                <i class="fas fa-key"></i>
              </template>
            </MazInput>
            <MazInput v-model="Seleccionado.contacto"
                      placeholder="Contacto"
                      :autocomplete="autocompleteContacto" :disabled="loading || cargando"
                      class="maz-mb-3" required :loading="loading || cargando"
                      clearable>

            </MazInput>
            <MazInput v-model="Seleccionado.correo"
                      placeholder="Correo"
                      :autocomplete="autocompleteCorreo" :disabled="loading || cargando"
                    class="maz-mb-3"    :loading="loading || cargando"
                      clearable>

            </MazInput>
            <MazInput v-model="Seleccionado.url"
                      placeholder="URL"
                      :autocomplete="autocompleteURL" :disabled="loading || cargando"
                     class="maz-mb-3"   :loading="loading || cargando"
                      clearable>

            </MazInput>
          </form>
        </ValidationObserver>
      </template>
    </modal>
    <div>
    </div>
  </div>
</template>

<script>

  import { MazSelect } from 'maz-ui'
  import { MazDraggableList } from 'maz-ui'
  import { MazInputTags } from 'maz-ui'
  

  export default {
    name: 'Ubicaciones-Menu-Config',
    components: {
     MazSelect,
      MazDraggableList,
      MazInputTags
    },
    props: {
      value: Array,

      Categoria: { type: String, default: 'Campos configurables' },
      Titulo: { type: String, default: 'Campos' },
      Ayuda: { type: String, default: '' },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        //   Campos: [],
        datos: {
          etiqueta: '',
          comentarios: '',

        },
        TipoDato: '',
        TiposDatos: [
          /*                SiNo,
                Texto,
                Entero,
                Decimal,
                Fecha,
                Hora,
                Catalogo,*/
          { label: 'Si/No', value: 'SiNo' },
          { label: 'Texto', value: 'Texto' },
          { label: 'Entero', value: 'Entero' },
          { label: 'Decimal', value: 'Decimal' },
          { label: 'Fecha', value: 'Fecha' },
          { label: 'Hora', value: 'Hora' },
          { label: 'Catalogo', value: 'Catalogo' },
          { label: 'Archivo', value: 'Archivo' },
          { label: 'Archivos', value: 'Archivos' }
        ],
        Opciones: [],
        Modal: false,
        cargando: false,
        Seleccionado: null,

        Erroneo: ''
      }
    },
    computed: {
      Campos: {
        get: function () {
          return this.value;
        },
        set: function (valor) {
          //this.datos.porHoras = (valor == "true");
          this.$emit('input', valor);
        }
        /*      if (this.value == undefined)
                return [];*/

      },
      autocompleteFecha() {
        return `${this.$options.name}-Fecha`;
      },
      autocompleteLinkID() {
        return `${this.$options.name}-LinkID`;
      },
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },
      autocompleteContacto() {
        return `${this.$options.name}-Nombre`;
      },
      autocompleteCorreo() {
        return `${this.$options.name}-Correo`;
      },
      autocompleteURL() {
        return `${this.$options.name}-URL`;
      },
      Config() {
        {
          return {
            labelKey: this.campoEtiqueta,
            searchKey: this.campoEtiqueta,
            valueKey: this.campoValor
          };
        }
      },
    },

    watch: {

    },
    mounted() {

    },
    created() {
    },
    methods: {
      Nuevo() {
        this.Seleccionado = {
          requerido: false,
          etiqueta: '',
          nombre: '',
          descripcion: '',
          tipoDeDato: 'Texto',
          catalogo: [],
        };
        this.Campos.push(this.Seleccionado);
        this.Modal = true;
      },
      Editar(item) {
        this.Seleccionado = item;
        this.Modal = true;
      },
      Borrar(item) {
        var Pos = this.Campos.indexOf(item);
        this.Campos.splice(Pos, 1);
      },
      guardar() {

      }

    }
  }
</script>

<style scoped>
</style>
