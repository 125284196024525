<template>
  <div>
    <div class="pt-2 pt-md-8">

      <div class="row">
        <div class="col-lg-7 col-md-9 d-inline-flex ">

          <i class="fas fa-door-open fa-3x mr-2"></i>
          <p>{{Ayuda}}</p>

        </div>
        <div class="col-lg-5 col-md-3 ">
          <b-row align-h="end" class="m-2">
            <base-button icon
                         round :loading="cargando" @click="Actualiza">
              <i class="fas fa-sync"></i>
            </base-button>


            <base-button icon @click="MostrarFiltrar=true"
                         round :loading="cargando || MsgError.length>0">
              <i class="fas fa-filter"></i>
            </base-button>

            <base-button v-if="$SuscripcionesUsuarios.PerfilID=='Administrador'" icon @click="MostrarImportar=true"
                         round :loading="cargando || MsgError.length>0">
              <i class="fas fa-upload"></i>
            </base-button>

          </b-row>
        </div>
      </div>



    </div>


    <div class="mt-2">

      <PeriodoSelect v-model="BaseGetsListado" desde="FHDesde" hasta="FHHasta" class="mb-3" :Mostrar.sync="MostrarFiltrar"
                     placeholder="Periodo de consulta de accesos"
                     :mostrarAlCargar="true" :guardarAdicionales="guardarAdicionales"
                     @cambio="Actualiza" :loading="cargando">



            <MazInput v-model="Nombre"
                      placeholder="Filtrar por nombre"
                      autocomplete="autocompleteNombre"
                       :loading="cargando" class="mb-2"
                      clearable>
              <!--    <i slot="icon-left"
    class="tim-icons icon-single-02"></i>-->
            </MazInput>

      </PeriodoSelect>

      <Main-Cargando :cargando="cargando" :MsgError="MsgError" />
      <div v-if="!MsgError && !cargando && info " class="card-columns-listado ">
        <div v-for="registro in info.listado" v-bind:key="registro.id" class="card   mb-3" :style="registro | ColorStyle">

          <router-link :to="{}" @click.native="Mostrar(registro)">

            <div class="card-header p-2">
              <div>
                <img :src="registro | Foto" class="rounded-circle float-left mr-2 mt-0" width="56px" style="height:56px">

                <!--

  <h4 class="mb-1 pb-0 text-truncate ">{{registro.colaborador.linkID}}-{{registro.colaborador.etiqueta}}</h4>
  <h5 class="mb-1 pb-0"> {{registro.fechaHora | FFechaHora}}</h5>
  <div>

    <span class="mb-0 pb-0 text-truncate ">{{registro.dispositivo.etiqueta}} </span>

  </div>
    -->
                <i class="pull-right pr-2 pt-2" :class="registro | Icono"></i>
                <h5 class="mb-1 pb-0"> {{registro.fechaHora | FFechaHora}}</h5>
                <h6 v-if="registro.dispositivo" class="mb-1 pb-0 text-truncate ">{{registro.dispositivo.etiqueta}} </h6>
                <h6 v-if="registro.ubicacion" class="mb-1 pb-0 text-truncate ">{{registro.ubicacion.etiqueta}} </h6>
                <h5 class="mb-0 pb-0 text-truncate "> {{registro.persona.etiqueta}}</h5>
                <h5 class="mb-0 pb-0 text-truncate "> {{registro.persona.empresa}}</h5>
                <h5 v-if="registro.menu" class="mb-0 pb-0 text-truncate "> Menu: {{registro.menu}}</h5>
              </div>
            </div>

          </router-link>
        </div>

      </div>

      <base-pagination v-if="!cargando && info && info.totalPaginas>1" :pageCount="info.totalPaginas" v-model="Pagina"></base-pagination>

    </div>

    <EdicionModal v-if="MostrarEdicion" :Mostrar.sync="MostrarEdicion" :Ayuda="Ayuda" v-model="Seleccionado"
                  v-on:guardado="Actualiza"></EdicionModal>

    <Importar v-if="MostrarImportar" :Mostrar.sync="MostrarImportar"
              v-on:guardado="Actualiza"></Importar>

    <NuevoModal v-if="MostrarNuevo" :Mostrar.sync="MostrarNuevo" :Ayuda="Ayuda"
                v-on:guardado="Actualiza"></NuevoModal>

    <AccesosEdicionComp v-if="CargarModalAcceso" :Mostrar.sync="ModalAcceso" :Acceso="SeleccionadoAcceso" :Colaborador="SeleccionadoColaborador"></AccesosEdicionComp>

  </div>
</template>

<script>
  /*
  import { Cascader } from 'element-ui';
  import { Collapse, CollapseItem } from 'element-ui';
  */
  import Servicio from '@/api-services/accesos.service';
  import Base from '@/api-base/base';
  import Colaboradores from '@/api-services/colaboradores.service';

  //import AccesosEdicionComp from './Edicion.comp.vue';
  const AccesosEdicionComp = () => import( /* webpackChunkName: "accesos" */ './Edicion.comp.vue');

  //import NuevoModal from './Nuevo.modal.vue';
  const NuevoModal = () => import( /* webpackChunkName: "accesos" */ './Nuevo.modal.vue');

  //import EdicionModal from './Edicion.modal.vue';
  const EdicionModal = () => import( /* webpackChunkName: "accesos" */ './Edicion.modal.vue');

  const PeriodoSelect = () => import( /* webpackChunkName: "personal" */ './Periodo.select.vue');
  import swal from 'sweetalert2';

  const Importar = () => import( /* webpackChunkName: "accesos" */ './Importar.vue');
  

  import moment from 'moment'
  //var moment = require('moment');
  moment.locale('es-mx');


  export default {


    name: 'Accesos-listado',
    data() {
      return {
        MostrarFiltrar: false,
        MostrarNuevo: false,
        MostrarEdicion: false,
        MostrarImportar: false,


        Nombre: '',

        BaseGetsListado: {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 50,
          filtro: '',

          FHDesde: '',
          FHHasta: '',


        },
        Filtro: null,
        cargandoFiltro: true,
        valorFiltro: null,
        Filtros: null,


        CargarModalAcceso: false,
        ModalAcceso: false,
        SeleccionadoAcceso: {},
        SeleccionadoColaborador: {},

        Seleccionado: null,

        Ayuda: 'Registros de entrada o salida de visitantes.',
        info: null,
        cargando: false,
        MsgError: ''
      }
    },
    components: {
      EdicionModal,
      NuevoModal,
      Importar,
      PeriodoSelect,
      AccesosEdicionComp
    },
    watch: {
      Filtro(valor) {
        switch (valor) {
          case "FiltroAccesos":
            this.CargaFiltro();
            break;
        }
      },
    },
    filters: {
      SubTitulo(value) {


        if (value.creadoPorUsuario !== null)
          return `${value.usuario.nombre} por ${value.creadoPorUsuario.nombre} `;
        return value.usuario.nombre;
      },
      Foto(Registro) {
        if (Registro.urlFoto != null && Registro.urlFoto != "")
          return Registro.urlFoto;
        return Colaboradores.FotoURL(Registro.colaborador);
      },

      Icono(value) {
        return Servicio.Icono(value);
      },
      Titulo(value) {
        var desde = Base.fecha(value.fechaHora);
        var Etiqueta = "Desconocido";
        switch (value.tipoAcceso) {
          case 3:
            Etiqueta = "Inicio";
            break;
          case 4:
            Etiqueta = "Fin";
            break;
          case 5:
            Etiqueta = "Pregunta";
            break;
          case 6:
            Etiqueta = "Respuesta";
            break;
          case 7:
            Etiqueta = "Sin Respuesta";
            break;
        }
        return `${desde.toLocaleString()} ${Etiqueta}`;
      },
      FFechaHora(value) {
        var Fecha = moment(value);
        return Fecha.format("ddd, DD/MMM/YY, h:mm:ss a");
      },
      ColorStyle(value) {
        return `border-left: 10px solid ${Servicio.Color(value)}`;
      },
      Color(value) {
        return Servicio.Color(value);
      },

    },
    computed: {
      autocompleteNombre() {
        return `${this.$options.name}-Nombre`;
      },
      Pagina: {
        get: function () {

          return this.BaseGetsListado.PaginaNo + 1;
        },
        set: function (valor) {
          this.BaseGetsListado.PaginaNo = valor - 1;
          this.Actualiza();
        }
      },
    },
    mounted() {
      /*let date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      this.rango = `${day}-${month}-${year}`;
      this.CargaAccesos(this.rango);*/
      return;
      /*   try {

             Reuniones.getReuniones().then((response) => {
                 if (response.data === undefined) {
                     this.errored = true;
                     return;
                 }
                 console.debug(response.data);
                 this.info = response.data;
                 this.loading = false;

                 // this.$router.push('/reuniones');
             }).catch((error) => {
                 console.error(error.response);
                 this.errored = true;
                 //alert(error.response.data.error.mensaje);
             });
         }
         catch (error) {
             // alert('Error desconocido, intente nuevamente');
             console.error(error);
             this.errored = true;
             // expected output: ReferenceError: nonExistentFunction is not defined
             // Note - error messages will vary depending on browser
         }*/
    },
    methods: {
      ObtenIcono(Tipo) {
        return Base.ObtenIcono(Tipo);
      },
      Filtrar(node, keyword) {
        ///Es padre
        if (node.label == node.value)
          return false;
        var Filtro = keyword.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toUpperCase();
        var Etiqueta = node.label.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toUpperCase();
        return (Etiqueta.indexOf(Filtro) > -1);
      },
      CargaFiltro() {
        if (this.Filtros != null)
          return;
        this.cargandoFiltro = true;
        Servicio.FiltroGets(this, {}, (response) => {

          var Filtros = [];
          var Ubicaciones = [];
          response.data.ubicaciones.forEach(e => {
            Ubicaciones.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "Ubicaciones", label: "Ubicaciones", children: Ubicaciones });

          var Dispositivos = [];
          response.data.dispositivos.forEach(e => {
            Dispositivos.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "Dispositivos", label: "Dispositivos", children: Dispositivos });

          var TiposAccesos = [];
          response.data.tiposAccesos.forEach(e => {
            TiposAccesos.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "TiposAccesos", label: "Tipos de Accesos", children: TiposAccesos });

          
          this.Filtros = Filtros;
          this.cargandoFiltro = false;
        })
      },

      Borrar(seleccionado) {
        //console.debug(id);
        swal.fire({
          title: '¿Estas seguro?',
          text: `Estas por borrar un Centro de Costo (${seleccionado.etiqueta}).`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn  btn-danger ',
            cancelButton: 'btn '
          },
          confirmButtonText: 'Si, Borrarlo',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then((resultado) => {
          console.debug(resultado);
          if (resultado.isConfirmed)

            Servicio.delete(this, seleccionado.id, (response) => {
              //console.debug(response.data);
              swal.fire({
                title: 'Borrado',
                text: 'El registro se ha borrado satisfactoriamente.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-fill'
                },
                buttonsStyling: false
              });
              this.Actualiza();
            });

        });
      },
      Editar(seleccionado) {
        this.MostrarEdicion = false;
        this.Seleccionado = seleccionado;

        this.MostrarEdicion = true;
      },
      OnFiltrar(filtro) {
        //     this.BaseGetsListado.Filtro = filtro;
        this.Actualiza();
      },
      Actualiza() {

        if (this.Nombre == "")
          this.BaseGetsListado.filtro = "";
        else
          this.BaseGetsListado.filtro = this.Nombre;

        Servicio.gets(this, this.BaseGetsListado, (response) => {
          var T = response.data;
        //  console.debug(T);
          T.dispositivos.forEach((element) => {
            if (element.ubicacionID) {
              var c = T.ubicaciones.filter(item => item.id == element.ubicacionID);
              if (c.length > 0)
                element.ubicacion = c[0];
            }
          });
          T.listado.forEach((element) => {
          //  console.debug(element);
            element.colaborador = null;
            element.dispositivo = null;
            element.ubicacion = null;
            if (element.colaboradorID) {
              var c = T.colaboradores.filter(item => item.id == element.colaboradorID);
              if (c.length > 0)
                element.colaborador = c[0];
            }
            if (element.dispositivoID) {
              var c = T.dispositivos.filter(item => item.id == element.dispositivoID);
              if (c.length > 0)
                element.dispositivo = c[0];
            }
            if (element.ubicacionID) {
              var c = T.ubicaciones.filter(item => item.id == element.ubicacionID);
              if (c.length > 0)
                element.ubicacion = c[0];
            }
          });
          //var R = countries.filter(item => item.iso3 == this.selectValue);
          this.info = T;
        }, (MsgError, error) => {
        }, () => {
          this.cargando = false;

        });
      },
      /*  MuestraAcceso(e) {
            console.debug("MuestraAcceso");
            console.debug(e);
            var GPS = JSON.parse(e.gps);
            console.debug(GPS);
            let google = window.google
            let map = document.getElementById('map-canvas');

            const NPos = new google.maps.LatLng(GPS.Lat, GPS.Lng);
            //console.debug(NPos);
            const mapOptions = {
                zoom: 15,
                scrollwheel: false,
                center: NPos,
                mapTypeId: google.maps.MapTypeId.ROADMAP,

            }
            this.ModalAcceso = true;
            map = new google.maps.Map(map, mapOptions);
            //map.center = NPos;
            const marker = new google.maps.Marker({
                position: NPos,
                map: map,
                animation: google.maps.Animation.DROP,
                title: 'Hello World!'
            });
            const circle = new google.maps.Circle({
                center: NPos,
                radius: GPS.Acc,
                map: map,//your map,
                fillColor: '#FF0000',//color,
                fillOpacity: 0.35,//opacity from 0.0 to 1.0,
                strokeColor: '#FF0000', //stroke color,
                strokeOpacity: 0.8,//opacity from 0.0 to 1.0
               // animation: google.maps.Animation.DROP,
            });
            console.debug(marker);
            console.debug(circle);
        },*/
      /*  CargaAccesos(rango) {
          this.loading = true;
          var Fechas = rango.split(" ");
          console.debug(Fechas);
          var Desde = Base.fechaDMY(Fechas[0]);
          var Hasta = null;
          if (Fechas.length > 1)
            Hasta = Base.fechaDMY(Fechas[2]);
          else
            Hasta = Base.fechaDMY(Fechas[0]);
          Hasta.setHours(23);
          Hasta.setMinutes(59);
          Hasta.setSeconds(59);
          var sDesde = Desde.toISOString();
          var sHasta = Hasta.toISOString()
          console.debug(Desde);
          console.debug(sDesde);
          console.debug(sHasta);

          try {

            Accesos.getAccesos(Desde, Hasta).then((response) => {
              if (response.data === undefined) {
                this.errored = true;
                return;
              }
              console.debug(response.data);
              this.info = response.data;
              this.loading = false;

              // this.$router.push('/reuniones');
            }).catch((error) => {
              console.error(error.response);
              this.errored = true;
              //alert(error.response.data.error.mensaje);
            });
          }
          catch (error) {
            // alert('Error desconocido, intente nuevamente');
            console.error(error);
            this.errored = true;
            // expected output: ReferenceError: nonExistentFunction is not defined
            // Note - error messages will vary depending on browser
          }
        },
        */


      CerroCalendario(selectedDates, dateStr) {

        // console.debug(selectedDates);
        //console.debug(this.rango);
        ///Es importante tomar la fecha de dateStr ya que del modelo aun no se ha actualizado
        console.debug(dateStr);
        //  this.CargaAccesos(dateStr);
        //console.debug(instance);


      },
      CambioCalendario(selectedDates, dateStr) {

        //console.debug(this.rango);
        //              console.debug(selectedDates);
        console.debug(dateStr);
        // console.debug(instance);
      },
      Mostrar(registro) {
        this.CargarModalAcceso = true;
        this.SeleccionadoAcceso = registro;
        this.SeleccionadoColaborador = registro.colaborador;

        this.ModalAcceso = true;

      },
      guardarAdicionales(Filtro) {
        if (this.Filtro == "FiltroAccesos") {
          this.valorFiltro.forEach(f => {

            if (f.length > 1) {
              if (Filtro[f[0]] == undefined)
                Filtro[f[0]] = "";
              else
                Filtro[f[0]] += ",";
              Filtro[f[0]] += f[1];
              /*
              if (NValor[f[0]] == undefined)
                NValor[f[0]] = [];
              NValor[f[0]].push(f[1]);*/
            }

          });
        }
        return true;
      }
    }
  }
</script>


<style lang="scss" scoped>



  @media (min-width: 35em) {
    .card-columns-listado {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 55em) {
    .card-columns-listado {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 80em) {
    .card-columns-listado {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 97em) {
    .card-columns-listado {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 114em) {
    .card-columns-listado {
      -webkit-column-count: 6;
      -moz-column-count: 6;
      column-count: 6;
      orphans: 1;
      widows: 1;
    }
  }

  .card {
    display: inline-block; /*    // Don't let them vertically span multiple columns */
    width: 100%; /*    // Don't let their width change*/
    min-height: 5.2em
  }
</style>

