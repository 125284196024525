<template>
  <div>
    <modal :show.sync="mMostrar"
           class="modal-black" :appendToBody="false"
           modal-classes="modal-danger modal-dialog-centered">

      <h6 slot="header" class="modal-title" id="modal-title-notification">Información del acceso</h6>

      <template>


          <div class="mb-2" style="height: 60px">
            <img :src="Colaborador | Foto" class="rounded-circle float-left mr-2" width="60px">
            <!----><small class="pull-right m-0 p-0 text-muted">{{Colaborador.linkID}} </small>
            <h2 class="align-middle mb-0">{{Colaborador.etiqueta}}</h2>
          </div>



          <base-alert :type="Acceso | Tipo" class="px-2 py-2 mb-0">
            <span class="alert-inner--icon"><i :class="Acceso | Icono"></i></span>
            <span class="alert-inner--text ml-2 "> <strong>{{Acceso.fechaHora | FFechaHora}} </strong>{{Acceso | MostrarDatos}}</span>
          </base-alert>
          <div v-if="HayGPS" class="d-flex justify-content-center mt-2">
            <base-dropdown v-if="Acceso.ubicacion" :title-classes="TypeUbicacion(Acceso.ubicacion)" :title="Acceso.ubicacion.etiqueta"
                           icon>

              <!-- <a v-if="!Acceso.ubicacion.confirmada" class="dropdown-item" href="#">Confirmar</a>-->
              <a class="dropdown-item" href="#" v-on:click="Cambiar">Cambiar</a>
              <a class="dropdown-item" href="#" v-on:click="Editar">Editar</a>

            </base-dropdown>
            <base-button v-else slot="title" size="sm" type="default" v-on:click="Cambiar" class="">
              Etiquetar ubicación
            </base-button>
          </div>
          <div class="px-1 py-1 mb-0"> </div>
          <div :class="HayGPS?'':'d-none'" id="acceso-map-canvas" class="acceso-map-canvas card shadow border-0" style="height: 300px;"></div>
          <div v-if="Acceso.evidencias && Acceso.evidencias.length>0" class="d-flex justify-content-center mt-2">
            <base-dropdown>
              <base-button slot="title" type="info" size="sm" class="dropdown-toggle" icon="fas fa-paperclip">
                Evidencia
              </base-button>
              <a v-for="evidencia in Acceso.evidencias" v-bind:key="evidencia.evidenciaID" class="dropdown-item" :href="evidencia.url">{{evidencia.contenido}} </a>

            </base-dropdown>

          </div>

      </template>

      <template slot="footer">

      </template>
    </modal>
       <UbicacionesEdicionComp :Mostrar.sync="UbicacionMostrar" :Ubicacion="UbicacionSeleccionada" v-on:nueva="OnUbicacionSeleccionada"></UbicacionesEdicionComp>
     <UbicacionesElegirComp :Mostrar.sync="UbicacionElegirMostrar" :GPS="GPSSeleccionada" v-on:ubicacion="OnUbicacionSeleccionada"></UbicacionesElegirComp>
  </div>
</template>

<script>
  import { API_KEY } from '@/api-base/maps.apikey';
  import { Loader, LoaderOptions } from "google-maps";
  const loader = new Loader(API_KEY);

  import Base from '@/api-base/base';
  import Accesos from '@/api-services/accesos.service';
  import Ubicaciones from '@/api-services/ubicaciones.service';
  import Colaboradores from '@/api-services/colaboradores.service';
  import moment from 'moment'
  moment.locale('es-mx');

      import UbicacionesEdicionComp from '@/Vistas/Ubicaciones/Edicion.comp.vue';
  import UbicacionesElegirComp from '@/Vistas/Ubicaciones/Elegir.comp.vue';
  export default {
    name: "AccesosEdicionComp",
    props: {
      Guardando: {
        type: Boolean,
        default: false,
        description: "Indica si se esta guardando la información"
      },
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Acceso: Object,
      Colaborador: Object
    },
    components: {
            UbicacionesEdicionComp,
             UbicacionesElegirComp,
    },
    data() {
      return {
        mAcceso: {},
        mColaborador: {},
        mMostrar: false,
        mUbicacion: {},
        Map: null,
        Marker: {},
        Circle: null,
        UbicacionSeleccionada: {},
        UbicacionMostrar: false,
        GPS: { lat: 19.4270219, lng: -99.1681479, acc: 10},
        GPSSeleccionada: {},
        UbicacionElegirMostrar: false,

      };
    },
    computed: {
      HayGPS() {
        return (this.Acceso.gps && this.Acceso.gps.acc > 0);
      }
    },
    watch: {
      mMostrar: function (val) {
        //  console.debug(`mMostrar = ${val}`);
        if (this.Mostrar != val) {
          this.$emit('update:mostrar', val)
          // this.Mostrar = val;
        }
      },
      Acceso(Acceso) {
        // console.debug(Acceso);

        this.mAcceso = Acceso;
      },
      Mostrar(Mostrar) {
    
        this.mMostrar = Mostrar;

        if (Mostrar) {
          if (this.Acceso.gps && this.Acceso.gps.acc > 0) {
            this.GPS = this.Acceso.gps;
            //  console.debug(this.GPS);
            let google = window.google;
            const NPos = new google.maps.LatLng(this.GPS.lat, this.GPS.lng);

            this.Marker.setPosition(NPos);
            //setTimeout(() => { this.Marker.setPosition(NPos); }, 2000);

            this.Circle.setCenter(NPos);
            this.Circle.setRadius(parseFloat(this.GPS.acc));
            this.Map.setCenter(NPos);
            /*  var Bounds = this.Circle.getBounds();
              console.debug(Bounds);
             // this.Map.fitBounds(Bounds, 10);*/
          }
          else
            this.GPS = null;
        }
      }
    },
    filters: {
      Foto(Colaborador) {
        return Colaboradores.FotoURL(Colaborador);
      },
      Icono(value) {
        return Accesos.Icono(value);

      },
      FFechaHora(value) {
        var Fecha = moment(value);
        return Fecha.format("ddd, DD/MMM/YY, h:mm:ss a");
      },
      MostrarDatos(value) {
        //var desde = Base.fecha(value.fechaHora);
        var Etiqueta = Accesos.sTipoAcceso(value)
        var Mostrar = `${Etiqueta}`;
        if (value.comentario)
          Mostrar = `${Mostrar} ${value.comentario}`;
        //console.debug(value);
        if (value.dispositivo !== undefined && value.dispositivo !== null && value.dispositivo.etiqueta !== "")
          Mostrar = `${Mostrar} / ${value.dispositivo.etiqueta}`;
        /*   if (value.creadoPorUsuario !== null)
               return `${Mostrar} por ${value.creadoPorUsuario.nombre} `;*/
        return Mostrar
      },
      Tipo(value) {
        return Accesos.typeTipoAcceso(value);
      },
    },
    methods: {
      OnUbicacionSeleccionada(Ubicacion) {
        console.debug(Ubicacion);
        if (Ubicacion != undefined) {

          Accesos.putUbicacion(this.Acceso.accesoID, Ubicacion.ubicacionID).then((response) => {
            this.Acceso.ubicacion = Ubicacion;
            console.debug(response);
            this.$notify({ type: 'success', title: 'Guardado' });
            // this.$router.push('/reuniones');
          }).catch((error) => {
            this.$notify({ type: 'danger', title: 'Error desconocido, intente nuevamente"' });
            console.error(error.response);
            //alert("No se pudo guardar, intente nuevamente");
            //alert(error.response.data.error.mensaje);
          });

        }

        else {
          this.UbicacionSeleccionada = {};
          this.UbicacionMostrar = true;
        }
      },
      Cambiar() {
        this.GPSSeleccionada = this.GPS;
        console.debug(this.GPSSeleccionada);
        this.UbicacionElegirMostrar = true;
      },
      Editar() {
        this.UbicacionSeleccionada = this.Acceso.ubicacion;
        this.UbicacionMostrar = true;

      },
      CreaMapa() {
        let google = window.google;
        this.Map = document.getElementById('acceso-map-canvas');
        console.debug(this.GPS);
        const NPos = new google.maps.LatLng(this.GPS.lat, this.GPS.lng);
        //console.debug(this.Map);
        const mapOptions = {
          zoom: 16,
          scrollwheel: true,
          center: NPos,

          mapTypeId: google.maps.MapTypeId.ROADMAP,

        }
        this.Map = new google.maps.Map(this.Map, mapOptions);


      },

      Guardar() {

        this.props.Mostrar = false;
      },
      ActualizaDireccion() {


      },
      TypeUbicacion(Ubicacion) {
        if (!Ubicacion.confirmada)
          return 'btn btn-warning'
        return 'btn btn-default';
        
      },

    },
    mounted: function () {
      this.mMostrar = this.Mostrar;
      var Yo = this;
      if (this.HayGPS) {
        this.GPS = this.Acceso.gps;
      }
      loader.load().then(function (google) {
       // Yo.CreaMapa();
       // let google = window.google;
        var DivMap = document.getElementById('acceso-map-canvas');
        console.debug(JSON.stringify( Yo.GPS));
        const NPos = new google.maps.LatLng(Yo.GPS.lat, Yo.GPS.lng);
        console.debug(NPos);
        console.debug(JSON.stringify(NPos));
        const mapOptions = {
          zoom: 16,
          scrollwheel: true,
          center: NPos,

          mapTypeId: google.maps.MapTypeId.ROADMAP,

        }
        Yo.Map = new google.maps.Map(DivMap, mapOptions);
        //map.center = NPos;
        Yo.Marker = new google.maps.Marker({
          position: NPos,
          map: Yo.Map,
          // draggable: true,
          animation: google.maps.Animation.DROP,
          title: 'Ubicación'
        });


        //console.debug(this.mPrecision);
        Yo.Circle = new google.maps.Circle({
          center: Yo.Marker.position,
          radius: parseFloat(Yo.GPS.acc),
          map: Yo.Map,//your map,
          fillColor: '#FF0000',//color,
          fillOpacity: 0.35,//opacity from 0.0 to 1.0,
          strokeColor: '#FF0000', //stroke color,
          strokeOpacity: 0.8,//opacity from 0.0 to 1.0
          // animation: google.maps.Animation.DROP,
        });

      });
    },
  };
</script>
