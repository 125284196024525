<template>
  <div class="p-2">
    <TomaFoto :Mostrar.sync="MostrarTomaFoto"
              v-on:guardar="OnGuardarFoto" :cargando="cargando"
              :errorMsg="errorMsg"
              Categoria="Checar Asistencia" Titulo="Reconocimiento facial"
              :OcultarSeleccionar="OcultarSeleccionar"
              CerrarMsg="" :AutoGuardado="true" />

    <base-button v-if="!ModoChecador" @click="Anclar()" type="primary" block>
      Modo Checador
    </base-button>
    <base-button v-if="PuedeSalir" @click="DesAnclar()" block>
      Salir de Modo Checador
    </base-button>
  </div>
</template>

<script>
  import moment from 'moment'
  moment.locale('es-mx');

  import Usuarios from '@/api-base/usuarios.service';
  import Servicio from '@/api-services/accesos.service';
  import TomaFoto from '@/Vistas-base/Blobs/TomaFoto.vue';
  import Colaboradores from '@/api-services/colaboradores.service';
  import Ubicaciones from '@/api-services/ubicaciones.service';
  import LiGps from '@/api-base/LiGps';
  import swal from 'sweetalert2';
  import Rondines from '@/api-services/rondines.service';


  export default {
    name: 'Accesos-Nuevo',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Centros de costos' },
      Titulo: { type: String, default: 'Nuevo' },
      Ayuda: { type: String, default: 'El centro de costos es una unidad del negocio que genera costos para la empresa, y también beneficios, pero de forma indirecta. Ej. Centro de costos administrativos (CCA).' },

      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },
    components: {
      TomaFoto,
    },
    data() {
      return {
        datos: {
          etiqueta: '',
          comentarios: '',

        },

        OcultarSeleccionar: true,

        Modal: false,
        cargando: false,

        Erroneo: '',
        ModoChecador: false,
        PuedeSalir: false,

        CargaTomaFoto: false,
        MostrarTomaFoto: false,
        errorMsg: '',

        UsuarioID: '',
        comentario: '',
        Ubicaciones: [],
        UbicacionesFiltradas: [],
        UbicacionNombre: '',
        GPS: { Lat: 0, Lng: 0, Acc: 1000 },

        UltimoAcceso: null,
        EsSalida: false,
        UbicacionSeleccionada: null,
        UbicacionNueva: false,
      }
    },
    computed: {
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },

    },

    watch: {
      MostrarTomaFoto(Mostrar) {
        this.Modal = Mostrar;
      },
      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
        else
          this.ChecarCelular();
      }
    },
    destroyed() {
      LiGps.NotificacionQuita(this.OnGps);
    },
    mounted() {
      if (localStorage.ModoChecador== "true") {
        this.ModoChecador = true;
        Usuarios.getPerfilLocal((PerfilLocal) => {
          this.UsuarioID = PerfilLocal.usuario.usuarioID;
        });
      }
      if (LiGps.IniciaEscucha())
        LiGps.NotificacionAgrega(this.OnGps);
      Ubicaciones.getsGPS(this, (response) => {
        this.Ubicaciones = response.data.listado;
        //this.UbicacionesFiltradas = Ubicaciones.FiltrarGPS(this.Ubicaciones,);
        this.FiltrarUbicaciones();
      }, () => { }, () => { });


      this.Modal = this.Mostrar;

    },
    created() {
    },
    methods: {
      DesAnclar() {
        swal.fire({
          title: '¿Salir de Modo Checador?',
          text: `¿Deseas desactivar el modo checador?`,
          icon: 'question',
          showCancelButton: true,
          showCloseButton: true,
          // showDenyButton: true,
          customClass: {
            confirmButton: 'btn  ',
            cancelButton: 'btn '
          },
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          buttonsStyling: false
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.ModoChecador = "false";
            this.$router.replace("/");
          }
        });
      },

      Anclar() {
        //swal
        swal.fire({
          title: '¿Modo Checador?',
          text: `Convierte este dispositivo en un checador`,
          icon: 'question',
          showCancelButton: true,
          showCloseButton: true,
          // showDenyButton: true,
          customClass: {
            confirmButton: 'btn  ',
            cancelButton: 'btn '
          },
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          buttonsStyling: false
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.ModoChecador = "true";
            this.$router.replace("/accesos/checador");
          }
        });
      },
      OnGps(pos) {
        console.debug(pos);
        this.GPS.Lat = pos.coords.latitude;// = { Lat: pos.coords.latitude, Lng: pos.coords.longitude, Acc: pos.coords.accuracy }
        this.GPS.Lng = pos.coords.longitude;
        this.GPS.Acc = pos.coords.accuracy;
        /* if (this.GPS.accuracy > 1000)
          this.GPS.accuracy = 1000;*/
        this.FiltrarUbicaciones();
      },
      FiltrarUbicaciones() {
        if (!this.UbicacionValida)
          this.UbicacionesFiltradas = [];
        else {
          //trae otros cercanos pero los marca como deshabilitados
          this.UbicacionesFiltradas = Ubicaciones.FiltrarGPS(this.Ubicaciones, this.GPS.Lat, this.GPS.Lng, this.GPS.Acc, 500);
          if (this.UbicacionSeleccionada == null) {
            if (this.UbicacionesFiltradas.length > 0) {
              var Uno = this.UbicacionesFiltradas[0];
              if (Uno.Dentro) {
                this.SeleccionaUbicacion(Uno);
              }
            }
          }
        }
      },
      SeleccionaUbicacion(Ubicacion) {
        //: disabled = "!ubicacion.Dentro"
        if (!Ubicacion.Dentro) {
          this.$notify({ type: 'warning', title: `Estas a ${Number.parseFloat(Ubicacion.Distancia).toFixed(0)}m debes encontrarte a menos de ${Number.parseFloat(Ubicacion.Requerido).toFixed(0)}m` });
          return;
        }
        //  this.UbicacionNombre = Ubicacion.nombre;
        this.UbicacionNombre = '';
        this.UbicacionSeleccionada = Ubicacion.id;
        // console.log(Boton);
      },
      nuevo() {
        Servicio.post(this, this.datos, (response) => {
          this.$emit('guardado', response.data);

          this.Cerrar();

        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
        });
      },
      Cerrar() {
        this.datos = {
          etiqueta: '', descripcion: ''
        };
        this.Modal = false;
      },
      ChecarCelular() {
        this.CargaTomaFoto = true;
        this.MostrarTomaFoto = true;
      },
      Guardar(colaboradorID, urlFoto) {
        var UbicacionID = null;
        if (this.UbicacionSeleccionada) {
          var Ubicacion = this.UbicacionesFiltradas.find(x => x.id == this.UbicacionSeleccionada);
          // console.debug(Ubicacion);
          if (Ubicacion) {
            UbicacionID = Ubicacion.id;
            if (!Ubicacion.Dentro) {
              //Para mandar error
              this.SeleccionaUbicacion(Ubicacion);
              return;
            }
          }
        }
        Servicio.post(this, {
          comentario: this.comentario,
          gps: this.GPS,
          ubicacionEtiqueta: this.UbicacionValida ? this.UbicacionNombre : null,
          tipoAcceso: this.EsSalida ? "Salida" : "Entrada",
          fechaHora: moment().format(),
          ubicacionID: UbicacionID,
          colaboradorID: colaboradorID,
          urlFoto: urlFoto,
        }, (response) => {
          this.comentario = "";
          this.$notify({ type: 'success', title: `Guardado` });
          this.$emit('guardado', response.data);
          //this.EsSalida = !this.EsSalida;
          this.UltimoAcceso = response.data;
          if (this.UltimoAcceso.ubicacion) {
            var Ubicacion = this.Ubicaciones.find(Ubicacion => Ubicacion.id == this.UltimoAcceso.ubicacion.id);
            if (Ubicacion == null) {
              this.Ubicaciones.push(this.UltimoAcceso.ubicacion);
              this.FiltrarUbicaciones();
            }
            this.UbicacionSeleccionada = this.UltimoAcceso.ubicacion.id;
          }
          else
            if (this.UltimoAcceso.ubicacionID)
              this.UbicacionSeleccionada = this.UltimoAcceso.ubicacionID;
            this.UbicacionNueva = false;

            if (this.UltimoAcceso.activarRondines != null && this.UltimoAcceso.activarRondines == true) {
              //
              Rondines.setColaboradorID(this.UltimoAcceso.colaboradorID);
              this.$router.replace("/rondines/empleado");
            }

          //this.$notify({ type: 'success', title: 'Se ha checado' });
          // this.Cerrar();
        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
        });
      },

      OnGuardarFoto(LaFoto) {
        this.errorMsg = "";
        this.PuedeSalir = false;
        console.debug("OnGuardarFoto");
        var Yo = this;
        Colaboradores.IdentificarPatch(this, LaFoto, (Progreso) => { }, (response) => {

          console.debug(response.data);
          var colaboradorID = response.data.colaborador.id;
          var UsuarioID = response.data.colaborador.usuarioID;
          //UsuarioID
          var urlFoto = response.data.rostro.urlThumb;
          swal.fire({
            title: '¿Registrar checada?',
            text: `Se identifico a ${response.data.colaborador.nombre}`,
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            // showDenyButton: true,
            customClass: {
              confirmButton: 'btn  ',
              cancelButton: 'btn '
            },
            confirmButtonText: '<i class="fas fa-sign-in-alt"></i> Entrada',
            cancelButtonText: '<i class="fas fa-sign-out-alt"></i> Salida',
            buttonsStyling: false
          }).then((result) => {
            if (result.isConfirmed) {
              Yo.EsSalida = false;
              Yo.Guardar(colaboradorID, urlFoto);
            } else if (result.isDismissed) {
              console.debug(result.dismiss);
              if (result.dismiss == "cancel") {
                Yo.EsSalida = true;
                Yo.Guardar(colaboradorID, urlFoto);
              }
              else
                if (this.ModoChecador)
                  if (this.UsuarioID == UsuarioID)
                    this.PuedeSalir = true;
            }
          });

          /* this.MostrarTomaFoto = false;
           var ND = new Date();
           this.mFotoURL = `${response.data.urlThumb}?rnd=${(ND.getTime())}`;*/
          //this.NoCorrectos++;
        }, (Msg, error) => {
          if (error.response != null) {
            switch (error.response.status) {
              case 400:
                this.errorMsg = "No se guardo, debe contener un rostro visible";
                return;
                break;
              case 404:
                this.errorMsg = "No se identifico el rostro";
                return;
                break;
            }

          }
          this.errorMsg = Msg;
          //this.Erroneos.push(Nombre);
        }, () => {

        });
      }
    }
  }
</script>

<style scoped>
</style>
