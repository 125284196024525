var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card p-3"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"card-category"},[_vm._v(_vm._s(_vm.Categoria))]),_c('h3',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.Titulo))]),(!_vm.OcultarSeleccionar)?_c('div',{staticClass:"d-flex flex-row my-2 px-2 text-left"},[_c('MazSwitch',{staticClass:"mr-2",model:{value:(_vm.MostrarTomaEnVivo),callback:function ($$v) {_vm.MostrarTomaEnVivo=$$v},expression:"MostrarTomaEnVivo"}}),_c('h5',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.MostrarTomaEnVivo?"Utilizando toma en vivo":"Utilizar toma en vivo"))])],1):_vm._e()]),_c('div',[(_vm.MostrarTomaEnVivo)?_c('div',{staticClass:"text-center"},[_c('MazBtnGroup',{attrs:{"items":_vm.camaras,"outline":"","size":"mini","color":"success"},model:{value:(_vm.deviceId),callback:function ($$v) {_vm.deviceId=$$v},expression:"deviceId"}}),(_vm.hayCamaras)?_c('vue-web-cam',{ref:"webcam",staticClass:"px-1 py-1 mb-1",attrs:{"device-id":_vm.deviceId,"width":_vm.Ancho,"height":_vm.Alto},on:{"started":_vm.onStarted,"stopped":_vm.onStopped,"error":_vm.onError,"cameras":_vm.onCameras,"camera-change":_vm.onCameraChange}}):_vm._e(),_c('div',{staticClass:"text-center"},[(_vm.hayCamaras && !_vm.cargando)?_c('base-button',{attrs:{"icon":"","round":""},on:{"click":_vm.onCapture}},[_c('i',{staticClass:"fas fa-camera"})]):_vm._e()],1)],1):_vm._e(),(!_vm.MostrarTomaEnVivo)?_c('picture-input',{ref:"pictureInput1",staticClass:"TomaFoto",attrs:{"width":_vm.Ancho,"height":_vm.Alto,"zIndex":_vm.zIndex,"crop":false,"margin":0,"size":"10","accept":_vm.Aceptar,"removable":false,"autoToggleAspectRatio":_vm.autoToggleAspectRatio,"toggleAspectRatio":_vm.toggleAspectRatio,"buttonClass":"btn btn-sm btn-primary button","hideChangeButton":true,"prefill":_vm.prefill,"prefillOptions":_vm.prefillOptions,"customStrings":{
                   upload: '<h1>Bummer!</h1>' ,
                   drag: 'Clic o arrastra una foto' ,
                   tap: 'Toca aquí para seleccionar una foto' ,
                   select: 'Selecciona una foto' ,
                   selected: '<p>Foto correctamente seleccionada</p>' ,
                   fileSize: 'Límite de tamaño excedido' ,
                   fileType: 'Tipo de archivo no soportado'
                   }},on:{"change":_vm.onChange}}):_vm._e(),_c('hr',{staticClass:"my-md-4"}),(!_vm.cargando && _vm.HayCambio && !_vm.MostrarTomaEnVivo && !_vm.AutoGuardado)?_c('base-button',{attrs:{"type":"primary","block":""},on:{"click":_vm.OnGuardar}},[_vm._v(" Guardar ")]):_vm._e(),_c('div',{staticClass:"text-muted text-center"},[(_vm.Erroneo)?_c('p',{staticClass:"badge badge-danger mr-2 text-wrap"},[_vm._v(" "+_vm._s(_vm.Erroneo)+" ")]):_vm._e(),(_vm.cargando)?_c('div',{staticClass:"text-center mt-7"},[_c('b-spinner',{attrs:{"type":"grow","label":"cargando..."}}),_vm._v(" Cargando... ")],1):_vm._e(),(_vm.CerrarMsg!='')?_c('a',{staticClass:"d-block mt-2",attrs:{"href":"#"},on:{"click":function($event){return _vm.Cerrar()}}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" "+_vm._s(_vm.CerrarMsg)+" ")]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }