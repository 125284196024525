<template>
    <modal :show.sync="mMostrar"
           gradient="default"
           modal-classes="modal-default modal-dialog-centered">

        <h6 slot="header" class="modal-title" id="modal-title-notification">Elige la ubicación o crea una nueva</h6>



        <div class="mt--5">
            <ul class="list-group ">
                <li class="list-group-item"><a href="#" v-on:click="Elegido()" > <i class="fas fa-plus"></i> Nuevo </a></li>
                <li v-for="ubicacion in Ubicaciones" v-bind:key="ubicacion.ubicacionID"
                    class="list-group-item">
                    <a href="#"  v-on:click="Elegido(ubicacion)"> <i class="fas fa-map-marker"></i> {{ubicacion.nombre}} / {{ubicacion.Distancia | Metros}} </a>
                </li>
            </ul>
        </div>

        <template slot="footer">

        </template>
    </modal>

</template>

<script>

    import Ubicaciones from '@/api-services/ubicaciones.service';

    export default {
        name: "UgicacionElegirComp",
        props: {
            Guardando: {
                type: Boolean,
                default: false,
                description: "Indica si se esta guardando la información"
            },
            Mostrar: {
                type: Boolean,
                default: false,
                description: "Indica si se mostrará"
            },
            //Ubicacion: Object,
            GPS: Object
        },
        components: {
        },
        data() {
            return {

                mMostrar: false,


            };
        },
        computed: {
            Ubicaciones: function () {
                if (this.GPS == undefined || this.GPS == null)
                    return Ubicaciones.ObtenUbicacionesGPS(19.4270219, -99.1681479, 20, 500, 10);
                return Ubicaciones.ObtenUbicacionesGPS(this.GPS.Lat, this.GPS.Lng, this.GPS.Acc, 500, 10);
            }
        },
        watch: {
            mMostrar: function (val) {
                //  console.debug(`mMostrar = ${val}`);
                if (this.Mostrar != val) {
                    this.$emit('update:mostrar', val)
                    // this.Mostrar = val;
                }
            },

            Mostrar(Mostrar) {
                //console.debug(`Mostrar = ${Mostrar}`);
                this.mMostrar = Mostrar;

            }
        },
        filters: {
            Metros(x) {
                return `${Number.parseFloat(x).toFixed(0)}m`;
            }
        },
        methods: {
            Elegido(Ubicacion) {
                //this.Ubicacion = Ubicacion;
                this.$emit('ubicacion', Ubicacion)
                this.mMostrar = false;
            },
            Cambiar() {
            },
            Editar() {
                this.UbicacionSeleccionada = this.Acceso.ubicacion;
                this.UbicacionMostrar = true;

            },



        },
        mounted: function () {
            //   this.ActualizaPin();
        },
    };
</script>