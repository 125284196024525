<template>
  <modal :show.sync="Modal"
         
         modalContentClasses="card"
         headerClasses="card-header">

    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">Accesos</h5>
      <h3 class="card-title">Importación</h3>
    </div>

    <template class="text-center">

      Importa accesos desde un archivo de
      <b>Excel</b>(
      <a href="#" @click="Plantilla()">
        ver plantilla
      </a>
      ) o desde el archivo generado por tu checador, después importa el archivo final. Ojo, cierra el archivo antes de importarlo.
      <DispositivosSelect placeholder="Dispositivo" :loading="cargando"  
                         v-model="DispositivoID" required />

      <b-form-file v-if="!cargando" ref="file-input" size="sm" v-model="Excel"
                   accept=".xls, .xlsx" browseText="Elegir"
                   class="text-nowrap text-truncate mt-2"
                   placeholder="Elige un archivo o arrastralo aquí"
                   drop-placeholder="Arrastra un archivo aquí aqui...">

      </b-form-file>
      <div class="text-center" v-if="!cargando">
        <base-button type="default" v-on:click="ImportarExcel">
          <i class="fas fa-upload"> </i> Importar
        </base-button>
        <a href="#" class="d-block mt-2" @click="Cerrar()">
          <i class="fas fa-chevron-left"> </i> Cancelar
        </a>
      </div>


      <Main-Cargando v-if="cargando" />
      <base-progress v-if="PT>=0" type="default" :height="8" :value="PT" :label="Etiqueta"></base-progress>
      <div class="text-muted text-center">

        <p v-for="Correcto in Correctos" :key="Correcto" class="badge badge-success mr-2">
          {{ Correcto }}
        </p>
        <p v-for="Erroneo in Erroneos" :key="Erroneo" class="badge badge-danger mr-2">
          {{ Erroneo }}
        </p>
      </div>
    </template>
  </modal>
</template>

<script>
  //import DispositivosSelect from '@/Vistas/Dispositivos/Select.vue';
  const DispositivosSelect = () => import( /* webpackChunkName: "dispositivos" */ '@/Vistas/Dispositivos/Select.vue');

  import Servicio from '@/api-services/accesos.service';
  export default {
    name: 'diarios-importar',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
    },
    components: {
      DispositivosSelect
    },
    data() {
      return {
        Modal: false,
        image: null,
        cargando: false,
        Archivos: [],
        Excel: null,

        CampoFoto: "Foto",
        Etiqueta: "Por iniciar",
        PT: -1,

        GuardandoPos: 0,
        Erroneos: [],
        Correctos: [],

        NoCorrectos: 0,
        DispositivoID: null,
      }
    },

    watch: {
      Mostrar(Mostrar) {
        //this.$options.name
        //   console.debug(this.$options.name);
        // console.debug(this.componentName);
        // console.debug(this);
        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        //console.debug(Mostrar);
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;

    },
    methods: {
      Plantilla() {
        //  this.$appInsights.trackEvent({ name: this.$options.name }, { Plantilla: true });
        Servicio.ExcelPlantillaGet(this, (response) => {
          console.debug(response.headers['content-type']);

          //console.debug(response);
          var contentDisposition = response.headers["content-disposition"];
          var filename = contentDisposition.split(';')[1].split('=')[1].replace('"', '').replace('"', '');

          console.debug(filename);/*
              var match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
              console.debug(match);
              var filename = match[1];
              console.debug(filename);*/

          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);

          console.debug("Creado Link");
          //link.download = label;

          link.setAttribute('download', filename);
          document.body.appendChild(link);

          link.click();
          console.debug("Finalizado");
          // URL.revokeObjectURL(link.href)
          //          this.$notify({ type: 'info', title: "Listado obtenido" });
        });
      },
      ImportarFotos() {
        this.NoCorrectos = 0;
        this.Erroneos = [];
        this.Correctos = [];
        if (this.Archivos.length <= 0) {
          this.Erroneos.push("Requere seleccionar fotos");
          this.$appInsights.trackEvent({ name: this.$options.name }, { Guardar: "Requere seleccionar fotos" });
          return;
        }

        this.ImportaFoto();
      },
      ImportaFoto() {
        if (this.GuardandoPos < this.Archivos.length) {
          this.PT = Math.ceil(this.GuardandoPos * 100 / this.Archivos.length);
          var Archivo = this.Archivos[this.GuardandoPos++];
          var Nombre = Archivo.name;
          console.debug(Nombre);
          this.Etiqueta = `Subiendo ${Nombre} ${this.GuardandoPos}/${this.Archivos.length}`
          var P = Archivo.name.indexOf(".");
          if (P <= 0) {

            this.Erroneos.push(`${Nombre} nombre inválido`);
            this.Guarda();
            return;
          }
          var Llave = Nombre.substring(0, P);
          // console.debug(Archivo.size);

          Servicio.FotoPatch(this, Llave, 0, Archivo, (Progreso) => { }, (response) => {
            console.debug(response.data);
            this.NoCorrectos++;
          }, () => {
            this.Erroneos.push(Nombre);
          }, () => {
            this.ImportaFoto();
          });



        }
        else {
          this.GuardandoPos = 0;
          this.PT = 100;
          this.Etiqueta = "Finalizado";

          if (this.NoCorrectos > 0) {
            this.$emit('guardado', this.NoCorrectos);
            var Correcto = `${this.NoCorrectos}/${this.Archivos.length} importado(s)`;
            this.$notify({
              type: 'success', title: Correcto
            });
            this.Correctos.push(Correcto);
          }

        }
      },
      ImportarExcel() {

        this.Erroneos = [];
        this.Correctos = [];
        if (this.Excel == null) {
          this.Erroneos.push("Requere archivo");
          this.$appInsights.trackEvent({ name: this.$options.name }, { Guardar: "Requere archivo" });
          return;
        }
        if (this.DispositivoID == null) {
          this.Erroneos.push("Requere Dispositivo");
          this.$appInsights.trackEvent({ name: this.$options.name }, { Guardar: "Requere Dispositivo" });
          return;
        }
        // console.debug(this.Excel.name);
        Servicio.ExcelPlantillaPatch(this, this.DispositivoID, this.Excel, (Progreso) => {
          //console.debug(Progreso);
        }, (response) => {
          // console.debug(response);
          if (response.data != null) {
            if (response.data.nuevos > 0)
              this.Correctos.push(`${response.data.nuevos}/${response.data.totales} nuevo(s)`);
            if (response.data.actualizados > 0)
              this.Correctos.push(`${response.data.actualizados}/${response.data.totales} actualizado(s)`);
            if (response.data.nuevos <= 0 && response.data.actualizados <= 0 && response.data.erroneos <= 0 && response.data.errores.length <= 0)
              this.Erroneos.push("No se encontraron registros");
            else {
              this.$emit('guardado', response.data.actualizados + response.data.nuevos);
            }
            for (var Pos = 0; Pos < response.data.errores.length; Pos++)
              this.Erroneos.push(response.data.errores[Pos]);
          }

          //console.debug(response);
        }, (MsgError, error) => {
          this.Erroneos.push(MsgError);
        });
      },
      formatNames(files) {
        return files.length === 1 ? files[0].name : `${files.length} archivos seleccionados`
      },
      Cerrar() {
        this.Archivos = [];
        this.cargando = false;
        this.Modal = false;
      }
    }
  }
</script>

<style scoped>
</style>
