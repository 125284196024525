<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         modalContentClasses="card"
         headerClasses="card-header">

    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">{{Categoria}}</h5>
      <h3 class="card-title">{{Titulo}}</h3>
      <span class="text-muted">{{Ayuda}}</span>
    </div>


    <template>

      <div v-for="registro in value" v-bind:key="registro.nombre" class="card   mb-3" >
        <router-link :to="{}" @click.native="$emit('click',registro)">
          <div class="card-header p-2">
            <h4 class="mb-0 pb-0 text-break ">{{registro.etiqueta}}</h4>
          </div>
        </router-link>
      </div>

      <a :href="Whatsapp" v-if="abrirWhatsapp">
        <h5>
          <i class="fab fa-whatsapp mr-2"></i>
          <span>Abrir Whatsapp</span>
        </h5>
      </a>

      <div class="text-muted text-center">

        <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
          {{ Erroneo }}
        </p>
        <div v-if="cargando" class="text-center mt-7">
          <b-spinner type="grow" label="cargando..."></b-spinner>
          Cargando...
        </div>
        <a href="#" class="d-block mt-2" @click="Cerrar()">
          <i class="fas fa-chevron-left"> </i> Cancelar
        </a>

      </div>
    </template>

  </modal>
</template>

<script>
  import Servicio from '@/api-services/ubicaciones.service';

  export default {
    name: 'Ubicaciones-Menu',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Noticiaciones' },
      Titulo: { type: String, default: 'Menú:' },
      Ayuda: { type: String, default: 'Las ubicaciones son lugares donde se esta realizando ciertos trabajos, como es el caso de las sucursales.' },
      value: Array,
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        datos: {
          etiqueta: '',
          comentarios: '',
          esSucursal: true,
          confirmada: true,
        },

        Modal: false,
        cargando: false,

        abrirWhatsapp: false,
        Whatsapp: '',
        Erroneo:''
      }
    },
    computed: {
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },

    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() { this.Modal = this.Mostrar;

    },
    created() {
    },
    methods: {
      nuevo() {
        Servicio.post(this, this.datos, (response) => {
          this.$emit('guardado', response.data);

          this.Cerrar();

        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
        });
      },
      Cerrar() {
        this.datos = {
          etiqueta: '',
          descripcion: '',
          esSucursal: true,
          confirmada: true,
        };
        this.Modal = false;
      },
    }
  }
</script>

<style scoped>
</style>
