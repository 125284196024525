export default {
  watchPositionID: null,
  ///Ultima ubicación conocida
  GPS:null,
  ///Agregar la funcion a notificar, recordar quitarla cuando ya no se use
  Notificaciones: [],
  NotificacionAgrega(Evento) {
    this.Notificaciones.push(Evento);
    if (this.GPS != null)
      Evento(this.GPS);
  },
  NotificacionQuita(Evento) {
    var Pos = this.Notificaciones.indexOf(Evento);
    if (Pos >= 0)
      this.Notificaciones.splice(Pos, 1);
  },
  ParaEscucha() {
    if (this.watchPositionID !== null)
      navigator.geolocation.clearWatch(this.watchPositionID);
    this.watchPositionID = null;
  },
  IniciaEscucha() {
    var options = {
      enableHighAccuracy: true,
      //Tiempo que pasa en que si no consigue la ubicación manda error
      timeout: 10000,
      maximumAge: 0
    };

    if (!("geolocation" in navigator)) {
      //  this.errorStr = 'Geolocation is not available.';
      console.error('Geolocation is not available.');
      localStorage.LiGPS = false;
      return false;
    }
    localStorage.LiGPS = true;
    if (this.watchPositionID !== null)
      return true;
    /*
    ParaEscucha();
      */
    this.watchPositionID = navigator.geolocation.watchPosition(pos => {
      this.Notificaciones.forEach(Notificacion => Notificacion(pos));
      this.GPS = pos;
    }, err => {
        console.error(err);
        localStorage.LiGPS = false;
      this.watchPositionID = null;
      //this.errorStr = err.message;
    }, options);
    return true;
  }
}
