<template>
  <div>
    <div id="map" class="full-screen-map"></div>
    <AccesosEdicionComp v-if="Cargado" :Mostrar.sync="ModalAcceso" :Acceso="SeleccionadoAcceso" :Colaborador="SeleccionadoColaborador"></AccesosEdicionComp>
  </div>

</template>
<script>
  import Servicio from '@/api-services/accesos.service';
  import Colaboradores from '@/api-services/colaboradores.service';
  import { API_KEY } from '@/api-base/maps.apikey';
  import { Loader, LoaderOptions } from "google-maps";
  import AccesosEdicionComp from './Edicion.comp.vue';


  const loader = new Loader(API_KEY);

  export default {
    name: 'Accesos-Mapa',

    data() {
      return {
        nav: null,
        info: null,
        cargando: false,
        error: '',

        ModalAcceso: false,
        SeleccionadoAcceso: {},
        SeleccionadoColaborador: {},

        Cargado: false,
        mapa: null,
      };
    },
    components: {
      AccesosEdicionComp
    },
    mounted() {
      this.Actualiza();
      let nav = document.getElementsByTagName('nav');
      if (nav.length > 0) {
        this.nav = nav[0];
      }
      this.nav.classList.add('fixed-top');
      this.nav.classList.remove('navbar-transparent');
      var Yo = this;
      loader.load().then(function (google) {

        // Regular Map
        const myLatlng = new google.maps.LatLng(19.4247222, -99.1709283);
        const mapOptions = {
          zoom: 13,
    //      mapTypeId: google.maps.MapTypeId.ROADMAP,
          center: myLatlng,
          scrollwheel: true,

          mapTypeId: google.maps.MapTypeId.ROADMAP,
    //      scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
 //         disableDefaultUI: true, // a way to quickly hide all controls
//          zoomControl: true,
   /*       styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#8ec3b9'
                }
              ]
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1a3646'
                }
              ]
            },
            {
              featureType: 'administrative.country',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878'
                }
              ]
            },
            {
              featureType: 'administrative.land_parcel',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#64779e'
                }
              ]
            },
            {
              featureType: 'administrative.province',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878'
                }
              ]
            },
            {
              featureType: 'landscape.man_made',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#334e87'
                }
              ]
            },
            {
              featureType: 'landscape.natural',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#023e58'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#283d6a'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#6f9ba5'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#023e58'
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#3C7680'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#304a7d'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#2c6675'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#9d2a80'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#9d2a80'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#b0d5ce'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#023e58'
                }
              ]
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be'
                }
              ]
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              featureType: 'transit.line',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#283d6a'
                }
              ]
            },
            {
              featureType: 'transit.station',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#3a4762'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#0e1626'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#4e6d70'
                }
              ]
            }
          ]*/
        };

        Yo.mapa = new google.maps.Map(
          document.getElementById("map"),
          mapOptions
        );
        //Yo.CargaListado();
  /*      const marker = new google.maps.Marker({
          position: myLatlng,
          title: "Regular Map!"
        });

        marker.setMap(Yo.mapa);*/
      });
    },
    beforeDestroy() {
      this.nav.classList.add('navbar-transparent');
      this.nav.classList.remove('bg-white');
      this.nav.classList.remove('fixed-top');
    },
    methods: {
      CargaListado() {
        if (!this.mapa)
          return false;
        if (!this.info)
          return false;
        var T = this.info;
        var Yo = this;
        console.debug(Yo.mapa);
        var bounds = new google.maps.LatLngBounds();

        T.listado.forEach((element) => {
          if (element.gps && element.gps.acc > 0) {
            var marker = {};
            const NPos = new google.maps.LatLng(element.gps.lat, element.gps.lng);
            if (element.colaborador.urlMiniatura != null && element.colaborador.urlMiniatura != "") {
              marker = new google.maps.Marker({
                position: NPos,
                //animation: google.maps.Animation.DROP,
                title: element.colaborador.etiqueta,
                icon: {
                  url: element.colaborador.urlMiniatura,
                  scaledSize: new google.maps.Size(46, 46)
                }
              });
            }
            else
              marker = new google.maps.Marker({
                position: NPos,
                //animation: google.maps.Animation.DROP,
                title: element.colaborador.etiqueta,
              });
            marker.setMap(Yo.mapa);

            google.maps.event.addListener(marker, 'click', function () {
              //console.log(elemento);
              // console.log(Yo);
              Yo.SeleccionadoAcceso = element;
              Yo.SeleccionadoColaborador = element.colaborador;
              Yo.ModalAcceso = true;
              Yo.Cargado = true;
            });

            bounds.extend(marker.position);
          }
        });
        this.mapa.fitBounds(bounds);
      },
      Actualiza() {
        this.error = '';
        var Yo = this;
        // this.cargando = true;
        Servicio.GetUltimoXColaboradorGPS(this, {}, (response) => {
          var T = response.data;


          T.listado.forEach((element) => {

           // console.debug(element);

            element.colaborador = null;
            element.dispositivo = null;
            element.ubicacion = null;
            if (element.colaboradorID) {
              var c = T.colaboradores.filter(item => item.id == element.colaboradorID);
              if (c.length > 0)
                element.colaborador = c[0];
            }
            if (element.dispositivoID) {
              var c = T.dispositivos.filter(item => item.id == element.dispositivoID);
              if (c.length > 0)
                element.dispositivo = c[0];
            }
            if (element.ubicacionID) {
              var c = T.ubicaciones.filter(item => item.id == element.ubicacionID);
              if (c.length > 0)
                element.ubicacion = c[0];
            }
          });
          //var R = countries.filter(item => item.iso3 == this.selectValue);
          this.info = T;
          console.debug(Yo.info);
          this.CargaListado();
         // window.setTimeout(this.CargaListado, 1000);
          
          //          this.$notify({ type: 'info', title: "Listado obtenido" });
        }, (MsgError, error) => {
          // console.debug(MsgError);
          this.error = MsgError;
          //this.$notify({ type: 'danger', title: MsgError });
        }, () => {
          this.cargando = false;

        });
      }
    }
  };
</script>
<style>
  #map {
    height: 100vh;
  }
</style>
